<div class="auth-connecting panel" *ngIf="status.status === 'connecting'">
	<div class="auth-state">
		Connecting to backend...
	</div>
</div>

<div class="auth-awaiting panel" *ngIf="status.status === 'awaiting'">
	<div class="auth-state">
		Awaiting authorization from channel admin...
	</div>
</div>

<div class="auth-connected panel" *ngIf="status.status === 'connected'">
	<div class="auth-state">
		Authorization received, frontend connected
	</div>
</div>

<div class="auth-rejected panel" *ngIf="status.status === 'rejected'">
	<div class="auth-state">
		Authorization failed
	</div>

	<div class="auth-action">
		<button type="button" class="btn btn-primary" (click)="retry()">Retry</button>
	</div>
</div>

<div class="auth-reconnecting panel" *ngIf="status.status === 'reconnecting'">
	<div class="auth-state">
		Connection lost; awaiting reconnect...
	</div>
</div>

<div class="auth-disconnected panel" *ngIf="status.status === 'disconnected'">
	<div class="auth-state">
		Unexpected connection failure
	</div>

	<div class="auth-action">
		<button class="btn btn-primary" (click)="retry()">Retry</button>
	</div>
</div>

<div class="auth-info">
	<div class="auth-id">
		ID: {{ id }}
	</div>

	<div class="auth-date">
		Last status change: {{ status.lastAction }}
	</div>

	<div class="auth-action">
		<button type="button" class="btn btn-primary" (click)="reset()">Force reset</button>
	</div>
</div>
