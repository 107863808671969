import { AfterContentInit, AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { ObservableComponentBase } from 'core/components/base/observable-component.base';
import { MonolithStateService } from 'core/services/state/monolith-state.service';
import { DocumentManager } from 'core/utility/document.manager';

import { ElementManager } from 'element/services/element.manager';
import { ELEMENT_TYPE_ENUM } from 'element/utility/element.enum';

import { IGenericElementDataModel } from 'element/models/element.models';
import { IViewElementModel, IViewModel } from 'profile/models/view.models';
import { VIEW_LAYOUT_TYPE_ENUM } from 'profile/utility/view.enum';

@Component({
	selector: 'stream-view',
	templateUrl: './view.component.html'
})
export class ViewComponent extends ObservableComponentBase implements OnInit, AfterViewInit, AfterContentInit {
	@HostBinding('class')
	private _viewClasses: string = '';

	@ViewChild('container', { read: ViewContainerRef, static: true })
	private _elementContainer: ViewContainerRef;

	@Input()
	public model: IViewModel;

	@Output()
	public loaded = new EventEmitter<string>();

	@Output()
	public unloaded = new EventEmitter<string>();

	private _isActive: boolean = false;
	private _isTransitioningFrom: boolean = false;
	private _viewVariants: string[] = [];

	constructor(
		private _documentManager: DocumentManager,
		private _elementManager: ElementManager,
		private _state: MonolithStateService
	) {
		super();
	}

	protected initSubscriptions(): Subscription[] {
		return [
			this._state.watchView()
				.subscribe((result) => {
					// setTimeout(() => {	// TODO: Firefox: "Expression has changed after it was checked"
					this._isActive = (result.active === this.model.id) ||
						(result.transitionTo === this.model.id);
					this._isTransitioningFrom = this._isActive &&
						result.transitionTo &&
						(result.transitionTo !== this.model.id);

					// set to incoming view variants if:
					// - 'transition-to' matches this view
					// - only applying variants ('active' matches incoming state change)
					if ((result.transitionTo === this.model.id) ||
						(!this._isTransitioningFrom &&
							(result.active === this.model.id))) {
						this._viewVariants = result.variants;
					}

					this.applyClasses();
					// }, 0);
				})
		];
	}

	public ngOnInit(): void {
		super.ngOnInit();

		// load view styles
		if (this.model.style) {
			this._documentManager.addStyle(`${this.model.id}-view-style`, this.model.style);
		}
	}

	public ngAfterViewInit(): void {
		if (!this.model) {
			// TODO: error - bad timing?
			return;
		}

		const id = `${this.model.id}-layout`;

		// load view layout - SVG or PNG
		switch (this.model.layout.type) {
			case VIEW_LAYOUT_TYPE_ENUM.SVG:
				this._documentManager.addSVG(id, this.model.layout.data, this._elementContainer.element.nativeElement.parentElement);
				break;
			case VIEW_LAYOUT_TYPE_ENUM.PNG:
				this._documentManager.addPNG(id, this.model.layout.data, this._elementContainer.element.nativeElement.parentElement);
				break;
			default:
				// TODO: error - unsupported layout type?
				break;
		}
	}

	public ngAfterContentInit(): void {
		if (!this.model) {
			// TODO: error - bad timing?
			return;
		}

		// add viewports as elements to load
		for (const viewport of this.model.viewports) {
			this.model.elements.push({
				id: `viewport-${viewport}`,
				type: ELEMENT_TYPE_ENUM.Viewport
			} as IViewElementModel<IGenericElementDataModel>);
		}

		// dynamically load elements of the view
		for (const element of this.model.elements) {
			this._elementManager.loadElement(this.model.id, element, this._elementContainer);
		}

		this.loaded.emit(this.model.id);
	}

	// #region Internal

	private applyClasses(): void {
		const classes: string[] = [
			`view-${this.model.id}`,
			...this._viewVariants
		];

		if (this._isTransitioningFrom) {
			classes.push('transition-from');
		}

		if (this._isActive) {
			classes.push('active');
		}

		this._viewClasses = classes.join(' ');
	}

	// #endregion
}
