import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { IHash } from 'core/models/hash.models';
import { safeAtoBstring } from './data.utility';

const VIEWPORT_HEIGHT: number = 1080;
const VIEWPORT_WIDTH: number = 1920;

// TODO: does this belong in core?
@Injectable()
export class DocumentManager {
	private _head: HTMLHeadElement;
	private _styles: IHash<HTMLStyleElement> = {};

	constructor(
		@Inject(DOCUMENT) private _document: Document
	) {
		this._head = this._document.head;
	}

	// document manager holds references
	public addStyle(id: string, content: string): HTMLStyleElement {
		const style = this.createElement('style', id, content);
		style.type = 'text/css';

		this._head.appendChild(style);

		this._styles[id] = style;

		return this._styles[id];
	}

	public removeStyle(id: string): void {
		if (!this._styles[id]) { return; }

		this._head.removeChild(this._styles[id]);

		delete this._styles[id];
	}

	// caller holds references
	public addPNG(id: string, content: string, container: HTMLElement): HTMLImageElement {
		const image = this.createElement('img', id);

		image.src = 'data:image/png;base64,' + content;

		container.appendChild(image);

		return image;
	}

	public addSVG(id: string, content: string, container: HTMLElement): SVGElement {
		// convert incoming SVG text string to an actual SVG element
		const template = this._document.createElement('template');
		const markup = safeAtoBstring(content);

		if (!markup) {
			return null;
		}

		template.innerHTML = markup;

		let svg: SVGElement;

		// get root svg element - ignore all preceding text / comments
		for (const node of Array.from(template.content.childNodes)) {
			if (node.nodeName === 'svg') {
				svg = node as SVGElement;
				break;
			}
		}

		svg.id = id;

		container.appendChild(svg);

		return svg;
	}

	// #region Internal

	private createElement<T extends keyof HTMLElementTagNameMap>(tag: T, id: string, content: string = null): HTMLElementTagNameMap[T] {
		const element = this._document.createElement(tag);
		element.id = id;

		if (content) {
			const markup = safeAtoBstring(content);
			if (!markup) {
				return null;
			}

			element.innerHTML = markup;
		}

		return element;
	}

	// #endregion
}
