<div class="donation-wrapper">
	<div class="donation-name">
		{{ model.name }}
	</div>
	<div class="donation-amount">
		{{ formatAmount() }}
	</div>
	<div class="donation-comment">
		{{ model.comment }}
	</div>
	<div class="donation-date">
		{{ formatDate() }}
	</div>
</div>
