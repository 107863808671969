import { Component } from '@angular/core';
import { Subscription } from 'rxjs';

import { MonolithStateService } from 'core/services/state/monolith-state.service';
import { formatCurrency } from 'core/utility/format.utility';

import { ElementBase } from 'element/components/element-base/element.base';
import { IChallengeModel } from 'element/models/challenge.models';
import { IChallengeElementDataModel } from 'element/models/element.models';

@Component({
	selector: 'stream-challenges-component',
	templateUrl: './challenges.component.html'
})
export class ChallengesComponent extends ElementBase<IChallengeElementDataModel> {
	public challenges: IChallengeModel[] = [];

	constructor(state: MonolithStateService) {
		super(state);
	}

	protected initSubscriptions(): Subscription[] {
		return [
			...super.initSubscriptions(),
			this.state.watchChallenges()
				.subscribe((result) => {
					this.challenges = result;
				})
		];
	}

	public formatAmount(challenge: IChallengeModel): string {
		return formatCurrency(challenge.amount, this.meta.hideDecimal ? 0 : 2, !this.meta.hideSymbol);
	}

	public formatTotal(challenge: IChallengeModel): string {
		return formatCurrency(challenge.count * challenge.amount, this.meta.hideDecimal ? 0 : 2, !this.meta.hideSymbol);
	}
}
