import { Injectable } from '@angular/core';
import * as uuid from 'uuid';

import { environment } from 'environment';

import { IHash } from 'core/models/hash.models';
import { IWebsocketConnectModel, IWebsocketHandler, IWebsocketHandlerEntry, IWebsocketMessageModel, WEBSOCKET_MESSAGE_TYPE } from 'core/models/websocket.models';
import { IWebsocketService } from './websocket.service';

@Injectable()
export class NativeWebsocketService implements IWebsocketService {
	private _websocket: WebSocket = undefined;
	private _handlers: IHash<IWebsocketHandlerEntry[]> = {};

	public connect(info: IWebsocketConnectModel): void {
		// already connected, do not attempt to connect again
		if (this.isConnected()) {
			return;
		}

		this._websocket = new WebSocket(environment.websocketServerUrl);

		this._websocket.onopen = (event: Event): any => {
			this._websocket.onmessage = (message: MessageEvent): any => {
				// TODO: auto-parse incoming fields w/ date types: - https://trello.com/c/c4DINcxF
				// https://blog.mariusschulz.com/2016/04/28/deserializing-json-strings-as-javascript-date-objects
				const data = JSON.parse(message.data) as IWebsocketMessageModel;

				const handlers = this._handlers[data.type];

				if (!handlers) {
					// TODO: log error for unhandled message type
					return;
				}

				// TODO: validate incoming token

				// execute all handlers for incoming message type
				for (const entry of handlers) {
					entry.handler(data.data);
				}
			};

			// detect unxpected connection drops
			this._websocket.onclose = (message: CloseEvent): any => {
				if (this._handlers[WEBSOCKET_MESSAGE_TYPE.LostConnection] && this._handlers[WEBSOCKET_MESSAGE_TYPE.LostConnection][0]) {
					this._handlers[WEBSOCKET_MESSAGE_TYPE.LostConnection][0].handler();
				}
			};

			// send 'connect' message to backend
			this.send({
				type: WEBSOCKET_MESSAGE_TYPE.FrontendConnect,
				data: {
					id: info.id,
					channel: info.channel,
					profile: info.profile
				},
				token: null
			} as IWebsocketMessageModel);
		};
	}

	public disconnect(clearHandlers: boolean = true): void {
		// clear all handlers if requested
		if (clearHandlers) {
			this._handlers = {};
		}

		// disconnect websocket
		this._websocket.close(3000, 'Client disconnected');
		delete this._websocket;
	}

	public handle(type: WEBSOCKET_MESSAGE_TYPE, handler: IWebsocketHandler<any>, token?: string): string {
		if (!this._handlers[type]) {
			this._handlers[type] = [];
		}

		// generate a token if one not supplied
		if (!token) {
			token = uuid.v4();
		}

		const entry = {
			handler: handler,
			token: token
		} as IWebsocketHandlerEntry;

		this._handlers[type].push(entry);

		return entry.token;
	}

	public unhandle(type: WEBSOCKET_MESSAGE_TYPE, token: string): void {
		if (!this._handlers[type]) { return; }

		const entryIndex = this._handlers[type].findIndex((x) => x.token === token);
		if (entryIndex > -1) {
			this._handlers[type].splice(entryIndex, 1);
		}
	}

	public send(message: IWebsocketMessageModel): void {
		if (!this.isConnected()) { return; }

		this._websocket.send(JSON.stringify(message));
	}

	// #region Internal

	private isConnected(): boolean {
		return this._websocket && (this._websocket.readyState === 1);
	}

	// #endregion
}
