<div *ngIf="musicInfo" class="music-container" [class.empty]="!isMusicPlaying">
	<div class="music-album">
		{{ musicInfo.album }}
	</div>
	<div class="music-artist">
		{{ musicInfo.artist }}
	</div>
	<div class="music-comment">
		{{ musicInfo.comment }}
	</div>
	<div class="music-genre">
		{{ musicInfo.genre }}
	</div>
	<div class="music-track-title">
		{{ musicInfo.trackTitle }}
	</div>
</div>
