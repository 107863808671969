import { Component, HostBinding, Inject } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import * as moment from 'moment';
import { Observable, of, Subject, Subscription, } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';

import { ObservableComponentBase } from 'core/components/base/observable-component.base';

import { AUTH_STATUS_ENUM, IAuthState } from 'core/models/auth.models';
import { IWebsocketConnectModel, IWebsocketHandler, WEBSOCKET_MESSAGE_TYPE } from 'core/models/websocket.models';
import { AuthManager } from 'core/services/auth/auth.manager';
import { AuthService } from 'core/services/auth/auth.service';
import { MonolithStateService } from 'core/services/state/monolith-state.service';
import { IWebsocketService, websocketServiceToken } from 'core/services/websocket/websocket.service';

import { IProfileCurrentModel } from 'profile/models/profile.models';
import { ProfileManager } from 'profile/services/profile.manager';

@Component({
	selector: 'stream-auth',
	templateUrl: './auth.component.html',
	styleUrls: ['./auth.component.scss']
})
export class AuthComponent extends ObservableComponentBase {
	@HostBinding('class.show')
	public show: boolean = true;

	public id: string;
	public status: IAuthState;

	private _params: Params;
	private _connect: Subject<void> = new Subject<void>();
	private _reconnectTimer: number;

	constructor(
		private _route: ActivatedRoute,
		private _authManager: AuthManager,
		private _authService: AuthService,
		private _profileManager: ProfileManager,
		private _state: MonolithStateService,
		@Inject(websocketServiceToken) private _websocket: IWebsocketService
	) {
		super();

		this.changeAuthState(AUTH_STATUS_ENUM.Connecting);
		this.handleAuthCommands();	// TODO: constructor, or init...?
	}

	protected initSubscriptions(): Subscription[] {
		return [
			this._connect
				.pipe(
					switchMap(() => {
						this.changeAuthState(AUTH_STATUS_ENUM.Connecting);

						return this.connect();
					})
				)
				.subscribe((status) => {
					if (status !== null) {
						this.changeAuthState(status);
					}
					else {
						this.changeAuthState(AUTH_STATUS_ENUM.Rejected);
					}
				}),
			this._route.queryParams
				.pipe(
					take(2)
				)
				.subscribe((params) => {
					this._params = params;
					this.retry();
				}),
			this._state.watchAuth()
				.subscribe((result) => {
					this.status = result;

					this.show = (result.status !== AUTH_STATUS_ENUM.Connected);

					// if disconnected (error, not forced) attempt reconnect cycle
					if (result.status === AUTH_STATUS_ENUM.Reconnecting) {
						this.attemptReconnect();
					}

					// if connected (after reconnect attempt), clear timer
					if ((result.status === AUTH_STATUS_ENUM.Connected)
						&& this._reconnectTimer) {
						clearTimeout(this._reconnectTimer);
						this._reconnectTimer = null;
					}
				})
		];
	}

	public retry(): void {
		this._connect.next(null);
	}

	public reset(): Observable<AUTH_STATUS_ENUM> {
		this._authManager.clearToken();

		location.replace(`?channel=${this._params['channel']}&key=${this._params['key']}`);

		return of(AUTH_STATUS_ENUM.Rejected);
	}

	// #region Internal

	private connect(): Observable<AUTH_STATUS_ENUM> {
		// if forcing a reset, ignore/clear existing token and refresh instance
		if (this._params['reset'] !== undefined) {
			return this.reset();
		}

		// already have a token, attempt reconnect
		const token = this._authManager.getToken();
		if (token) {
			return this.reconnect(token);
		}

		// no token, check incoming connection info
		const channel = this._params['channel'];
		const key = this._params['key'];

		// no connection info - reject
		if (!channel || !key) {
			return of(AUTH_STATUS_ENUM.Rejected);
		}

		// TODO: condense w/ reconnect
		return this._authService.connect(channel, key)
			.pipe(
				map((response) => {
					this._websocket.connect({
						id: response.id,
						channel: response.channel,
						profile: response.profile
					} as IWebsocketConnectModel);

					this._profileManager.setCurrentProfile({
						channel: response.channel,
						profile: response.profile
					} as IProfileCurrentModel);

					this.id = response.id;

					return AUTH_STATUS_ENUM.Awaiting;
				}),
				catchError((err, obs) => {
					// TODO: error message = handling
					return of(AUTH_STATUS_ENUM.Rejected);
				})
			);
	}

	private reconnect(token: string): Observable<AUTH_STATUS_ENUM> {
		return this._authService.reconnect()
			.pipe(
				map((response) => {
					this._websocket.connect({
						id: response.id,
						channel: response.channel,
						profile: response.profile
					} as IWebsocketConnectModel);

					this._profileManager.setCurrentProfile({
						channel: response.channel,
						profile: response.profile
					} as IProfileCurrentModel);

					this.id = response.id;

					return AUTH_STATUS_ENUM.Connected;
				}),
				catchError((err, obs) => {
					// reconnect failed
					// while trying to reconnect, so keep trying to reconnect
					if (this._reconnectTimer) {
						this.attemptReconnect();
						return of(AUTH_STATUS_ENUM.Reconnecting);
					}

					// using (bad) token, clear it out
					this._authManager.clearToken();
					return of(AUTH_STATUS_ENUM.Rejected);
				})
			);
	}

	private changeAuthState(state: AUTH_STATUS_ENUM): void {
		this._state.storeAuth({
			lastAction: moment.utc().toDate(),
			status: state
		});
	}

	private handleAuthCommands(): void {
		this._websocket.handle(WEBSOCKET_MESSAGE_TYPE.FrontendConnect, this.handleConnect);
		this._websocket.handle(WEBSOCKET_MESSAGE_TYPE.FrontendDisconnect, this.handleDisconnect);
		this._websocket.handle(WEBSOCKET_MESSAGE_TYPE.LostConnection, this.handleLostConnection);
	}

	// TODO: rename - not handling websocket connect, but the authorization
	// from the backend/admin
	private handleConnect: IWebsocketHandler<string> = (response): void => {
		this._authManager.setToken(response);
		this.changeAuthState(AUTH_STATUS_ENUM.Connected);
	}

	// forced disconnect; clear auth and connection
	private handleDisconnect: IWebsocketHandler<void> = (): void => {
		this.changeAuthState(AUTH_STATUS_ENUM.Disconnected);
		this._authManager.clearToken();

		// close websocket connection, but don't clear any active handlers
		this._websocket.disconnect(false);
	}

	// unexpected connection loss - clear connection info, keep token (if it exists)
	private handleLostConnection: IWebsocketHandler<void> = (): void => {
		// only set reconnecting / trouble state if unexpected
		if (this.status.status !== AUTH_STATUS_ENUM.Disconnected) {
			this.changeAuthState(AUTH_STATUS_ENUM.Reconnecting);
		}
	}

	// attempt reconnect after 10 seconds
	private attemptReconnect(): void {
		if (this._reconnectTimer) { clearTimeout(this._reconnectTimer); }

		// TODO: configurable, linear back-off, exponential backoff, limited retries, etc
		this._reconnectTimer = setTimeout(this.retry.bind(this) as TimerHandler, 10000);
	}

	// #endregion
}
