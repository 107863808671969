import { Inject, Injectable } from '@angular/core';

import { IHash } from 'core/models/hash.models';
import { IWebsocketHandler, WEBSOCKET_MESSAGE_TYPE } from 'core/models/websocket.models';
import { IWebsocketService, websocketServiceToken } from 'core/services/websocket/websocket.service';

import { MonolithStateService } from 'core/services/state/monolith-state.service';

import { IViewElementModel } from 'profile/models/view.models';

import { IMilestoneModel } from 'element/models/milestone.models';
import { IMusicInfoModel } from 'element/models/music-info.models';
import { IPlayerInfoModel } from 'element/models/player-info.models';

import { IBidGroupModel } from 'element/models/bid.models';
import { IChallengeModel } from 'element/models/challenge.models';
import { IDonationModel, IDonationTotalsModel } from 'element/models/donation.models';
import { IGenericElementDataModel } from 'element/models/element.models';
import { IGameModel } from 'element/models/game.models';
import { ELEMENT_TYPE_ENUM } from 'element/utility/element.enum';

@Injectable()
export class HandlerManager {
	private _elementHandlers: IHash<string> = {};
	private _profileHandlers: IHash<string> = {};
	private _systemHandlers: IHash<string> = {};

	constructor(
		private _state: MonolithStateService,
		@Inject(websocketServiceToken) private _websocket: IWebsocketService,
	) { }

	// using only generic; specialized handlers don't handle general element data
	public handleElement(element: IViewElementModel<IGenericElementDataModel>): string {
		const entryToken = `element-${element.type}-handler`;

		// only one handler per element type, elements listen to state
		const entry = this._elementHandlers[entryToken];
		if (entry) {
			return entryToken;
		}

		let handler: IWebsocketHandler<any>;
		let websocketMessageType: WEBSOCKET_MESSAGE_TYPE;

		switch (element.type) {
			// case ELEMENT_TYPE_ENUM.Attendees:
			// 	websocketMessageType = WEBSOCKET_MESSAGE_TYPE.Attendees;
			// 	handler = this.handleAttendees;
			// 	break;

			case ELEMENT_TYPE_ENUM.Bids:
				websocketMessageType = WEBSOCKET_MESSAGE_TYPE.Bids;
				handler = this.handleBids;
				break;

			case ELEMENT_TYPE_ENUM.Challenges:
				websocketMessageType = WEBSOCKET_MESSAGE_TYPE.Challenges;
				handler = this.handleChallenges;
				break;

			// case ELEMENT_TYPE_ENUM.Commentators:
			// 	websocketMessageType = WEBSOCKET_MESSAGE_TYPE.Commentators;
			// 	handler = this.handleCommentators;
			// 	break;

			case ELEMENT_TYPE_ENUM.CurrentGame:
				websocketMessageType = WEBSOCKET_MESSAGE_TYPE.CurrentGame;
				handler = this.handleCurrentGame;
				break;

			case ELEMENT_TYPE_ENUM.Donations:
				websocketMessageType = WEBSOCKET_MESSAGE_TYPE.Donations;
				handler = this.handleDonations;
				break;

			case ELEMENT_TYPE_ENUM.DonationTotals:
				websocketMessageType = WEBSOCKET_MESSAGE_TYPE.DonationTotals;
				handler = this.handleDonationTotals;
				break;

			case ELEMENT_TYPE_ENUM.Milestones:
				websocketMessageType = WEBSOCKET_MESSAGE_TYPE.Milestones;
				handler = this.handleMilestones;
				break;

			case ELEMENT_TYPE_ENUM.Music:
				websocketMessageType = WEBSOCKET_MESSAGE_TYPE.Music;
				handler = this.handleMusic;
				break;

			case ELEMENT_TYPE_ENUM.Players:
				websocketMessageType = WEBSOCKET_MESSAGE_TYPE.Players;
				handler = this.handlePlayers;
				break;

			case ELEMENT_TYPE_ENUM.Schedule:
				websocketMessageType = WEBSOCKET_MESSAGE_TYPE.Schedule;
				handler = this.handleSchedule;
				break;

			default:
				// unsupported element or no special handler needed
				return null;
		}

		const handlerToken = this._websocket.handle(websocketMessageType, handler.bind(this), entryToken);

		this._elementHandlers[entryToken] = handlerToken;

		return handlerToken;
	}

	public handleProfile<T>(type: WEBSOCKET_MESSAGE_TYPE, handler: IWebsocketHandler<T>): string {
		// only allow one handler per type at the profile level
		const currentHandler = this._profileHandlers[type];
		if (currentHandler) {
			// TODO: log error?
			return currentHandler;
		}

		const handlerToken = this._websocket.handle(type, handler);

		this._profileHandlers[type] = handlerToken;

		return handlerToken;
	}

	public handleSystem<T>(type: WEBSOCKET_MESSAGE_TYPE, handler: IWebsocketHandler<T>): string {
		throw new Error('Not implemented');
	}

	public unhandleProfile(type: WEBSOCKET_MESSAGE_TYPE): void {
		const handler = this._profileHandlers[type];

		if (handler) {
			this._websocket.unhandle(type, handler);
		}

		delete this._profileHandlers[type];
	}

	// #region Element handlers

	private handleBids(data: IBidGroupModel[]): void {
		this._state.storeBids(data);
	}

	private handleChallenges(data: IChallengeModel[]): void {
		this._state.storeChallenges(data);
	}

	private handleCurrentGame(data: IGameModel): void {
		this._state.storeCurrentGame(data);
	}

	private handleDonations(data: IDonationModel[]): void {
		this._state.storeDonations(data);
	}

	private handleDonationTotals(data: IDonationTotalsModel): void {
		this._state.storeDonationTotals(data);
	}

	private handleMilestones(data: IMilestoneModel[]): void {
		this._state.storeMilestones(data);
	}

	private handleMusic(data: IMusicInfoModel): void {
		this._state.storeMusic(data);
	}

	private handlePlayers(data: IPlayerInfoModel[]): void {
		this._state.storePlayers(data);
	}

	private handleSchedule(data: IGameModel[]): void {
		this._state.storeSchedule(data);
	}

	// #endregion
}
