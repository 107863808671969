import * as rfdc from 'rfdc';
const copier = rfdc();

export function deepCopy<T>(source: T): T {
	return copier(source);
}

export function safeAtoBstring(data: string): string {
	try {
		return atob(data);
	}
	catch (ex) {
		// TODO: log error
		return null;
	}
}

export function safeAtoBJson<T>(data: string): T {
	const converted = safeAtoBstring(data);

	try {
		return JSON.parse(converted) as T;
	}
	catch (ex) {
		// TODO: log error
		return null;
	}
}
