import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IAuthRequest, IAuthResponse } from 'core/models/auth.models';
import { ApiServiceBase } from 'core/services/api/api-service.base';

@Injectable()
export class AuthService extends ApiServiceBase {
	protected basePath: string = 'auth';

	constructor(http: HttpClient) {
		super(http);
	}

	public connect(channel: string, key: string): Observable<IAuthResponse> {
		return this.post<IAuthResponse>('connect', { channel: channel, key: key } as IAuthRequest);
	}

	public reconnect(): Observable<IAuthResponse> {
		return this.post<IAuthResponse>('reconnect', null);
	}
}
