import { Component } from '@angular/core';
import { Subscription } from 'rxjs';

import { MonolithStateService } from 'core/services/state/monolith-state.service';

import { ElementBase } from 'element/components/element-base/element.base';
import { IGenericElementDataModel } from 'element/models/element.models';
import { IGameModel } from 'element/models/game.models';

@Component({
	selector: 'stream-current-game-component',
	templateUrl: './current-game.component.html'
})
export class CurrentGameComponent extends ElementBase<IGenericElementDataModel> {
	public model: IGameModel = {} as IGameModel;

	constructor(state: MonolithStateService) {
		super(state);
	}

	protected initSubscriptions(): Subscription[] {
		return [
			...super.initSubscriptions(),
			this.state.watchCurrentGame()
				.subscribe((result) => {
					this.model = result;
				})
		];
	}
}
