import { Component } from '@angular/core';
import { Subscription } from 'rxjs';

import { MonolithStateService } from 'core/services/state/monolith-state.service';
import { formatCurrency } from 'core/utility/format.utility';

import { ElementBase } from 'element/components/element-base/element.base';
import { IGenericElementDataModel } from 'element/models/element.models';
import { IMilestoneModel } from 'element/models/milestone.models';

@Component({
	selector: 'stream-milestones-component',
	templateUrl: './milestones.component.html'
})
export class MilestonesComponent extends ElementBase<IGenericElementDataModel> {
	public milestones: IMilestoneModel[] = [];
	public donationTotal: number = 0;

	constructor(state: MonolithStateService) {
		super(state);
	}

	protected initSubscriptions(): Subscription[] {
		return [
			...super.initSubscriptions(),
			this.state.watchMilestones()
				.subscribe((result) => {
					this.milestones = result;
				}),
			this.state.watchDonationTotals()
				.subscribe((result) => {
					this.donationTotal = result.total;
				})
		];
	}

	public formatAmount(milestone: IMilestoneModel): string {
		if (milestone.useTotal) {
			return formatCurrency(this.donationTotal);
		}

		return formatCurrency(milestone.amount);
	}

	public formatGoal(milestone: IMilestoneModel): string {
		return formatCurrency(milestone.goal);
	}
}
