import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthManager } from 'core/services/auth/auth.manager';

@Injectable()
export class AttachJwtInterceptor implements HttpInterceptor {
	constructor(private _authManager: AuthManager) { }

	public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const token = this._authManager.getToken();
		if (token) {
			req = req.clone({
				setHeaders: {
					Authorization: `Bearer ${token}`
				}
			});
		}

		return next.handle(req);
	}
}
