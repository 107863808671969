import { Component } from '@angular/core';
import { Subscription } from 'rxjs';

import { MonolithStateService } from 'core/services/state/monolith-state.service';
import { formatCurrency } from 'core/utility/format.utility';

import { ElementBase } from 'element/components/element-base/element.base';
import { IDonationTotalsModel } from 'element/models/donation.models';
import { IGenericElementDataModel } from 'element/models/element.models';

@Component({
	selector: 'stream-donation-totals',
	templateUrl: './donation-totals.component.html'
})

export class DonationTotalsComponent extends ElementBase<IGenericElementDataModel> {
	public donationTotals: IDonationTotalsModel;

	constructor(state: MonolithStateService) {
		super(state);
	}

	protected initSubscriptions(): Subscription[] {
		return [
			...super.initSubscriptions(),
			this.state.watchDonationTotals()
				.subscribe((result) => {
					this.donationTotals = result;
				})
		];
	}

	// TODO: generic format pipe
	public formatAmount(value: number): string {
		return formatCurrency(value);
	}
}
