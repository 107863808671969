import { APP_STATE_ACTIONS, IStateAction } from './app-state.actions';
import { IAppState } from './app-state.model';

// TODO: split monolithic state
export const appReducer = (state: IAppState, action: IStateAction): IAppState => {
	switch (action.type) {
		case APP_STATE_ACTIONS.STORE_CURRENT_GAME:
			return { ...state, currentGame: action.payload };

		case APP_STATE_ACTIONS.STORE_SCHEDULE:
			return { ...state, schedule: action.payload };

		case APP_STATE_ACTIONS.STORE_DONATIONS:
			const newDonations = [...state.donations];

			for (const incoming of action.payload) {
				const donation = newDonations.find((x) => x.id === incoming.id);
				if (!donation) {
					// new donation - add to list
					newDonations.push(incoming);
				}
				else {
					// existing donation - update with incoming info
					// TODO: test if update works as intended
					donation.amount = incoming.amount;
					donation.comment = incoming.comment;
					donation.name = incoming.name;
					donation.date = incoming.date;
				}
			}

			return { ...state, donations: newDonations };

		case APP_STATE_ACTIONS.STORE_DONATION_TOTALS:
			return { ...state, donationTotals: action.payload };

		case APP_STATE_ACTIONS.STORE_MILESTONES:
			return { ...state, milestones: action.payload };

		case APP_STATE_ACTIONS.STORE_BIDS:
			return { ...state, bids: action.payload };

		case APP_STATE_ACTIONS.STORE_CHALLENGES:
			return { ...state, challenges: action.payload };

		case APP_STATE_ACTIONS.STORE_MUSIC:
			return { ...state, music: action.payload };

		case APP_STATE_ACTIONS.STORE_ATTENDEES:
			return { ...state, attendees: action.payload };

		case APP_STATE_ACTIONS.STORE_COMMENTATORS:
			return { ...state, commentators: action.payload };

		case APP_STATE_ACTIONS.STORE_PLAYERS:
			return { ...state, players: action.payload };

		// ----

		case APP_STATE_ACTIONS.STORE_ELEMENT_META:
			const elementToken = `${action.payload.view}:${action.payload.id}`;
			const newElementMeta = { ...state.elementMeta };
			newElementMeta[elementToken] = action.payload.meta;

			return { ...state, elementMeta: newElementMeta };

		// ----

		case APP_STATE_ACTIONS.STORE_AUTH:
			return { ...state, auth: action.payload };

		case APP_STATE_ACTIONS.STORE_VIEW:
			return { ...state, view: action.payload };

		case APP_STATE_ACTIONS.IMPORT_SAVED_DATA:
			const mergeData = {} as any;

			// only merge in data that's actually present
			for (const key of Object.keys(action.payload)) {
				if (action.payload[key]) {
					mergeData[key] = action.payload[key];
				}
			}

			return { ...state, ...mergeData };
		default:
	}

	return state;
};
