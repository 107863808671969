import { Component } from '@angular/core';
import { Subscription } from 'rxjs';

import { MonolithStateService } from 'core/services/state/monolith-state.service';

import { ElementBase } from 'element/components/element-base/element.base';
import { IDonationModel } from 'element/models/donation.models';
import { IGenericElementDataModel } from 'element/models/element.models';

@Component({
	selector: 'stream-donations-container-component',
	templateUrl: './donations-container.component.html',
})
export class DonationsContainerComponent extends ElementBase<IGenericElementDataModel> {
	public donations: IDonationModel[] = [];

	constructor(state: MonolithStateService) {
		super(state);
	}

	protected initSubscriptions(): Subscription[] {
		return [
			...super.initSubscriptions(),
			this.state.watchDonations()
				.subscribe((result) => {
					this.donations = result;
				})
		];
	}
}
