import { Inject, Injectable } from '@angular/core';

import { IStorageService, storageServiceToken } from 'core/services/storage/storage.service';

enum LOCAL_STORAGE_KEYS {
	Token = 'token'
}

@Injectable()
export class AuthManager {
	constructor(
		@Inject(storageServiceToken) private _storage: IStorageService
	) { }

	public getToken(): string {
		return this._storage.getValue(LOCAL_STORAGE_KEYS.Token);
	}

	public setToken(token: string): void {
		this._storage.setValue(LOCAL_STORAGE_KEYS.Token, token);
	}

	public clearToken(): void {
		this._storage.clearValue(LOCAL_STORAGE_KEYS.Token);
	}
}
