import { Inject, Injectable } from '@angular/core';

import { IHash } from 'core/models/hash.models';
import { IStorageService, storageServiceToken } from 'core/services/storage/storage.service';

import { ASSET_TYPE_ENUM } from 'core/models/asset.models';
import { DocumentManager } from 'core/utility/document.manager';

import { IProfileCurrentModel, IProfileModel } from 'profile/models/profile.models';

import { MonolithStateService } from 'core/services/state/monolith-state.service';
import { safeAtoBJson } from 'core/utility/data.utility';

enum LOCAL_STORAGE_KEYS {
	ProfileInfo = 'profile_info'
}

@Injectable()
export class ProfileManager {
	private _assets: IHash<IHash<string>> = {};

	constructor(
		private _documentManager: DocumentManager,
		private _state: MonolithStateService,
		@Inject(storageServiceToken) private _storage: IStorageService
	) { }

	public getCurrentProfile(): IProfileCurrentModel {
		return this._storage.getValue(LOCAL_STORAGE_KEYS.ProfileInfo) as IProfileCurrentModel;
	}

	public setCurrentProfile(newProfile: IProfileCurrentModel): void {
		this._storage.setValue(LOCAL_STORAGE_KEYS.ProfileInfo, newProfile);
	}

	public loadProfile(profile: IProfileModel): void {
		// set up profile styles
		for (const index in profile.styles) {
			if (!profile.styles[index]) { continue; }

			this._documentManager.addStyle(`${profile.id}-style-${index}`, profile.styles[index]);
		}

		// cache incoming asset data
		for (const asset of profile.assets) {
			if (!this._assets[asset.type]) {
				this._assets[asset.type] = {};
			}

			this._assets[asset.type][asset.id] = asset.data;
		}

		// set initial state data
		const data = safeAtoBJson<object>(profile.data);
		if (data) {
			this._state.importSavedData(data);
		}
	}

	public unloadProfile(profile: IProfileModel): void {
		// remove profile styles
		for (const index in profile.styles) {
			if (!profile.styles[index]) { continue; }

			this._documentManager.removeStyle(`${profile.id}-style-${index}`);
		}

		// clear cached assets
		this._assets = {};
	}

	// TODO: move asset handling to separate manager / cache - not specific to profile
	// returns cached base64-encoded asset content
	public getAsset(id: string, type: ASSET_TYPE_ENUM): string {
		if (!this._assets[type]) { return null; }

		return this._assets[type][id] || null;
	}
}
