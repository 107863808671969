import { Component } from '@angular/core';
import { Observable } from 'rxjs';

import { MonolithStateService } from 'core/services/state/monolith-state.service';

import { ElementBase } from 'element/components/element-base/element.base';
import { IGenericElementDataModel } from 'element/models/element.models';
import { IPlayerInfoModel } from 'element/models/player-info.models';

@Component({
	selector: 'stream-player-info-container-component',
	templateUrl: './player-info-container.component.html'
})

export class PlayerInfoContainerComponent extends ElementBase<IGenericElementDataModel> {
	public playersStream: Observable<IPlayerInfoModel[]>;

	constructor(state: MonolithStateService) {
		super(state);
	}

	protected initObservables(): Observable<IPlayerInfoModel[]>[] {
		return [
			...super.initObservables(),
			this.playersStream = this.state.watchPlayers()
		];
	}
}
