import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IProfileModel } from 'profile/models/profile.models';
import { ProfileApiService } from './profile-api.service';

import { MonolithStateService } from 'core/services/state/monolith-state.service';
import { FrontendApiService } from './frontend-api.service';

@Injectable()
export class ProfileService {
	constructor(
		private _api: ProfileApiService,
		private _frontendApi: FrontendApiService,
		private _state: MonolithStateService
	) { }

	public load(channel: string, profile: string): Observable<IProfileModel> {
		return this._api.load(channel, profile)
			.pipe(
				map((result) => {
					return result.profile;
				})
			);
	}

	public saveState(channel: string, profile: string): Observable<void> {
		const stateData = this._state.getSaveableState();

		return this._frontendApi.saveState(channel, profile, stateData);
	}
}
