import { Component, Input } from '@angular/core';
import * as moment from 'moment';

import { IDonationModel } from 'element/models/donation.models';

import { formatCurrency } from 'core/utility/format.utility';

@Component({
	selector: 'stream-donation-component',
	templateUrl: './donation.component.html'
})

export class DonationComponent {
	@Input()
	public model: IDonationModel;

	// TODO: convert to generic number format pipe
	public formatAmount(): string {
		return formatCurrency(this.model.amount);
	}

	// TODO: convert to generic date format pipe
	public formatDate(): string {
		return moment(this.model.date).toLocaleString();
	}
}
