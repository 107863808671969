import { Component, Input } from '@angular/core';

import { IDonationTotalsModel } from 'element/models/donation.models';

@Component({
	selector: 'stream-donations-progress-component',
	templateUrl: './donations-progress.component.html',
})
export class DonationsProgressComponent {
	@Input() public donationTotals: IDonationTotalsModel;
}
