import { IAuthState } from 'core/models/auth.models';
import { IHash } from 'core/models/hash.models';

import { IDonationModel, IDonationTotalsModel } from 'element/models/donation.models';
import { IGameModel } from 'element/models/game.models';
import { IMusicInfoModel } from 'element/models/music-info.models';
import { IPlayerInfoModel } from 'element/models/player-info.models';
import { IViewStateModel } from 'profile/models/view.models';

export interface IAppState {
	// "data" state - stored in backend, restored on reconnect
	currentGame: IGameModel;	// TODO: superfluous?
	schedule: IGameModel[];

	donations: IDonationModel[];
	donationTotals: IDonationTotalsModel;
	milestones: any[];
	bids: any[];
	challenges: any[];

	attendees: any[];
	commentators: any[];
	players: IPlayerInfoModel[];

	music: IMusicInfoModel;

	// element state - meta and static data
	elementMeta: IHash<any>;

	// "ephemeral" state - not saved in backend
	auth: IAuthState;
	view: IViewStateModel;
}

export const DEFAULT_APP_STATE: IAppState = {
	currentGame: null,
	schedule: [],

	donations: [],
	donationTotals: { goal: 0, total: 0 },
	milestones: [],
	bids: [],
	challenges: [],

	music: {
		album: '',
		artist: '',
		comment: '',
		genre: '',
		trackTitle: ''
	},

	attendees: [],
	commentators: [],
	players: [],

	elementMeta: {},

	auth: {
		lastAction: null,
		status: null
	},
	view: { active: null, transitionTo: null, variants: [] }
};
