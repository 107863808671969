import { OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export abstract class ObservableComponentBase implements OnInit, OnDestroy {
	private _observables: Observable<any>[] = [];
	private _subscriptions: Subscription[] = [];
	private _unscope: Subject<void> = new Subject<void>();

	public ngOnInit(): void {
		this._observables = [...this._observables, ...this.initObservables()];

		// ensure takeUntil indirect unsubscribe is the last in the pipe
		for (const observable of this._observables) {
			observable.pipe(
				takeUntil(this._unscope)
			);
		}

		this._subscriptions = [...this._subscriptions, ...this.initSubscriptions()];
	}

	public ngOnDestroy(): void {
		// ensure all registered subscriptions are unsubscribed
		if (this._subscriptions) {
			for (const subscription of this._subscriptions) {
				subscription.unsubscribe();
			}
		}

		this._unscope.next();
		this._unscope.complete();
	}

	protected initObservables(): Observable<any>[] {
		return [];
	}

	protected initSubscriptions(): Subscription[] {
		return [];
	}

	// use to add observable / subscription outside normal component lifecycles
	// typically on init or after view init
	protected addObservable(observable: Observable<any>): void {
		this._observables.push(observable);
	}

	protected addSubscription(subscription: Subscription): void {
		this._subscriptions.push(subscription);
	}
}
