import { Component, HostBinding, ViewChild, ViewContainerRef } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { ObservableComponentBase } from 'core/components/base/observable-component.base';
import { AUTH_STATUS_ENUM } from 'core/models/auth.models';
import { MonolithStateService } from 'core/services/state/monolith-state.service';

import { HandlerManager } from './services/handler.manager';

import { IProfileCurrentModel, IProfileModel } from './models/profile.models';
import { ProfileManager } from './services/profile.manager';
import { ProfileService } from './services/profile.service';

import { WEBSOCKET_MESSAGE_TYPE } from 'core/models/websocket.models';
import { IGenericElementDataModel } from 'element/models/element.models';
import { IViewElementUpdateModel, IViewTransitionModel, IViewVariantsModel } from './models/view.models';
import { ViewManager } from './services/view.manager';
import { VIEW_PROFILE_ID_ENUM } from './utility/view.enum';

@Component({
	selector: 'stream-profile',
	templateUrl: './profile.component.html'
})
export class ProfileComponent extends ObservableComponentBase {
	private _load = new Subject<void>();
	private _saveState = new Subject<void>();
	private _profile: IProfileModel;
	private _currentProfile: IProfileCurrentModel;
	private _stateTimer: number;

	@ViewChild('container', { read: ViewContainerRef, static: true })
	private _container: ViewContainerRef;

	@HostBinding('class.transitioning')
	private _isTransitioning: boolean = false;

	@HostBinding('class.show')
	private _show: boolean = false;

	private _listeningForTransition: boolean = false;

	constructor(
		private _handlerManager: HandlerManager,
		private _profileManager: ProfileManager,
		private _profileService: ProfileService,
		private _state: MonolithStateService,
		private _viewManager: ViewManager
	) {
		super();
	}

	protected initSubscriptions(): Subscription[] {
		return [
			this._load
				.pipe(
					switchMap(() => {
						this._currentProfile = this._profileManager.getCurrentProfile();

						return this._profileService.load(this._currentProfile.channel, this._currentProfile.profile);
					})
				)
				.subscribe((result) => {
					this.loadProfile(result);
				}),
			this._saveState
				.pipe(
					switchMap(() => {
						return this._profileService.saveState(this._currentProfile.channel, this._currentProfile.profile);
					})
				)
				.subscribe(),
			this._state.watchAuth()
				.subscribe((result) => {
					if (!result) { return; }

					switch (result.status) {
						case AUTH_STATUS_ENUM.Connected: // TODO: move to load profile?
							this._isTransitioning = true;
							this._show = true;

							if (!this._listeningForTransition) {
								this.handleTransition();
								this.handleVariants();
								this.handleElementMeta();
							}

							// initiate load of profile and data
							this._load.next();

							// start saving state on a regular interval
							this.startSavingState();
							break;
						case AUTH_STATUS_ENUM.Disconnected: // TODO: move to unload profile?
							// forced disconnect - unload
							this._show = false;
							this.unloadProfile();

							this.unhandleTransition();
							this.unhandleVariants();
							this.unhandleElementMeta();

							this.stopSavingState();

							break;
						case AUTH_STATUS_ENUM.Reconnecting:
							// unexpected disconnect; go to error view
							if (this._show) {
								this._viewManager.transitionToView(VIEW_PROFILE_ID_ENUM.Error);
							}

							this.stopSavingState();

							break;
					}
				}),
			this._state.watchView()
				.subscribe((result) => {
					if (!result) { return; }

					this._isTransitioning = !result.active || (result.transitionTo !== null);
				})
		];
	}

	// #region Internal

	private loadProfile(profile: IProfileModel): void {
		// if no previously-active view, start at setup
		let initialView: string = VIEW_PROFILE_ID_ENUM.Setup;
		let initialVariants: string[] = [];

		// use previously active view
		const storedViewState = this._viewManager.getStoredViewState();
		if (storedViewState && storedViewState.active) {
			initialView = storedViewState.active;
			initialVariants = storedViewState.variants;
		}

		// load incoming profile data
		this._profileManager.loadProfile(profile);

		// load profile's views
		for (const view of profile.views) {
			const viewComponent = this._viewManager.loadView(view, this._container);

			// watch for "loaded" event for view that should be shown first
			if (view.id === initialView) {
				viewComponent.loaded
					.pipe(
						take(1)
					)
					.subscribe((result) => {
						this._viewManager.transitionToView(result, 0, initialVariants);
					});
			}

			// aggregate websocket handlers for dynamic elements
			for (const element of view.elements) {
				this._handlerManager.handleElement(element);
			}
		}

		// save profile data for reload later (maybe)
		this._profile = profile;
	}

	private unloadProfile(): void {
		// TODO: disconnect element handlers?

		// transition off all views
		this._viewManager.transitionToView(null);

		// unload views
		for (const view of this._profile.views) {
			this._viewManager.unloadView(view.id);
		}

		// unload profile data
		this._profileManager.unloadProfile(this._profile);
	}

	private handleTransition(): void {
		this._handlerManager.handleProfile(WEBSOCKET_MESSAGE_TYPE.FrontendTransition, (data: IViewTransitionModel) => {
			this._viewManager.transitionToView(data.view, data.delay, data.variants);
		});

		this._listeningForTransition = true;
	}

	private unhandleTransition(): void {
		this._handlerManager.unhandleProfile(WEBSOCKET_MESSAGE_TYPE.FrontendTransition);

		this._listeningForTransition = false;
	}

	private handleVariants(): void {
		this._handlerManager.handleProfile(WEBSOCKET_MESSAGE_TYPE.FrontendVariants, (data: IViewVariantsModel) => {
			this._viewManager.applyVariants(data.variants);
		});
	}

	private unhandleVariants(): void {
		this._handlerManager.unhandleProfile(WEBSOCKET_MESSAGE_TYPE.FrontendVariants);
	}

	private handleElementMeta(): void {
		this._handlerManager.handleProfile(WEBSOCKET_MESSAGE_TYPE.ElementUpdate, <T extends IGenericElementDataModel>(data: IViewElementUpdateModel<T>) => {
			this._state.storeElementMeta(data.view, data.element.id, data.element.data);
		});
	}

	private unhandleElementMeta(): void {
		this._handlerManager.unhandleProfile(WEBSOCKET_MESSAGE_TYPE.ElementUpdate);
	}

	private startSavingState(): void {
		if (!this._stateTimer) {
			this._stateTimer = setInterval(() => {
				this._saveState.next();
			}, 60000) as any as number;
		}
	}

	private stopSavingState(): void {
		if (this._stateTimer) {
			clearInterval(this._stateTimer);
			this._stateTimer = 0;
		}
	}

	// #endregion
}
