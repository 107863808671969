import { AfterViewInit, Component } from '@angular/core';

import { ASSET_TYPE_ENUM } from 'core/models/asset.models';
import { MonolithStateService } from 'core/services/state/monolith-state.service';
import { safeAtoBstring } from 'core/utility/data.utility';

import { ElementBase } from 'element/components/element-base/element.base';
import { IGenericElementDataModel } from 'element/models/element.models';
import { ProfileManager } from 'profile/services/profile.manager';

@Component({
	selector: 'stream-markup-component',
	templateUrl: './markup.component.html'
})

export class MarkupComponent extends ElementBase<IGenericElementDataModel> implements AfterViewInit {
	public markup: string;

	constructor(
		state: MonolithStateService,
		private _profileManager: ProfileManager // TODO: profile manager shouldn't be the asset cache
	) {
		super(state);
	}

	public ngAfterViewInit(): void {
		// TODO: without the timeout: Expression has changed after it was checked
		setTimeout(() => {
			const encoded = this._profileManager.getAsset(this.elementId, ASSET_TYPE_ENUM.Markup);
			if (encoded) {
				this.markup = safeAtoBstring(encoded);
			}
		}, 0);
	}
}
