<div class="player-channel-info" *ngIf="currentChannelInfo">
	<div class="player-channel-pronouns">
		{{ playerModel.pronouns}}
	</div>
	<div class="player-channel-type" *ngIf="hasImage">
		<img [src]="imageData" />
	</div>
	<div class="player-channel-handle">
		{{ currentChannelInfo.name }}
	</div>
</div>
