import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ElementModule } from 'element/element.module';

import { ViewComponent } from './components/view/view.component';
import { ProfileComponent } from './profile.component';

import { FrontendApiService } from './services/frontend-api.service';
import { HandlerManager } from './services/handler.manager';
import { ProfileApiService } from './services/profile-api.service';
import { ProfileManager } from './services/profile.manager';
import { ProfileService } from './services/profile.service';
import { ViewManager } from './services/view.manager';

@NgModule({
	declarations: [
		ProfileComponent,
		ViewComponent
	],
	imports: [
		CommonModule,
		FormsModule,

		ElementModule
	],
	providers: [
		HandlerManager,
		FrontendApiService,
		ProfileApiService,
		ProfileManager,
		ProfileService,
		ViewManager
	],
	exports: [
		ProfileComponent
	],
	entryComponents: [
		ViewComponent
	]
})
export class ProfileModule { }
