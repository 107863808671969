<div class="current-game-wrapper">
	<div class="current-game-info">
		<div class="current-game-name">
			{{ model.gameName }}
		</div>
		<div class="current-game-platform">
			{{ model.gamePlatform }}
		</div>
		<div class="current-game-release-year">
			{{ model.gameReleaseYear }}
		</div>
		<div class="current-game-run-duration">
			{{ model.expectedDuration }}
		</div>
		<div class="current-game-runners">
			{{ model.runners }}
		</div>
		<div class="current-game-run-category">
			{{ model.runCategory }}
		</div>
	</div>
</div>
