import { Component, Input } from '@angular/core';

import { IGameModel } from 'element/models/game.models';

@Component({
	selector: 'stream-upcoming-game-component',
	templateUrl: './upcoming-game.component.html'
})

export class UpcomingGameComponent {
	@Input() public upcomingGameModel: IGameModel;
}
