import { IHash } from 'core/models/hash.models';

export interface IGenericElementDataModel extends IHash<any> {
	isVisible: boolean;
}

export interface IChallengeElementDataModel extends IGenericElementDataModel {
	hideDecimal: boolean;
	hideSymbol: boolean;
}

export const DEFAULT_ELEMENT_META: IGenericElementDataModel = {
	isVisible: true
};
