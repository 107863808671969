import { NgRedux, Selector } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { createSelector } from 'reselect';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IAuthState } from 'core/models/auth.models';
import { IHash } from 'core/models/hash.models';
import { APP_STATE_ACTIONS } from 'core/state/app-state.actions';
import { IAppState } from 'core/state/app-state.model';
import { deepCopy } from 'core/utility/data.utility';

import { IBidGroupModel } from 'element/models/bid.models';
import { IChallengeModel } from 'element/models/challenge.models';
import { IDonationModel, IDonationTotalsModel } from 'element/models/donation.models';
import { IGenericElementDataModel } from 'element/models/element.models';
import { IGameModel } from 'element/models/game.models';
import { IMilestoneModel } from 'element/models/milestone.models';
import { IMusicInfoModel } from 'element/models/music-info.models';
import { IPlayerInfoModel } from 'element/models/player-info.models';
import { IViewStateModel } from 'profile/models/view.models';

const getMonolithicState = (state: IAppState) => state;

const getAttendees = createSelector(getMonolithicState, (state: IAppState): any[] => state.attendees);
const getCommentators = createSelector(getMonolithicState, (state: IAppState): any[] => state.commentators);

const getCurrentGame = createSelector(getMonolithicState, (state: IAppState): IGameModel => state.currentGame);
const getSchedule = createSelector(getMonolithicState, (state: IAppState): IGameModel[] => state.schedule);

const getDonations = createSelector(getMonolithicState, (state: IAppState): IDonationModel[] => state.donations);
const getDonationTotals = createSelector(getMonolithicState, (state: IAppState): IDonationTotalsModel => state.donationTotals);
const getMilestones = createSelector(getMonolithicState, (state: IAppState): any[] => state.milestones);
const getBids = createSelector(getMonolithicState, (state: IAppState): any[] => state.bids);
const getChallenges = createSelector(getMonolithicState, (state: IAppState): any => state.challenges);

const getMusic = createSelector(getMonolithicState, (state: IAppState): IMusicInfoModel => state.music);
const getPlayers = createSelector(getMonolithicState, (state: IAppState): IPlayerInfoModel[] => state.players);

const getElementMeta = <T extends IGenericElementDataModel>(view: string, id: string) => createSelector((state: IAppState): IHash<any> => state.elementMeta, (y) => y[`${view}:${id}`] as T);

const getAuth = createSelector(getMonolithicState, (state: IAppState): IAuthState => state.auth);
const getView = createSelector(getMonolithicState, (state: IAppState): IViewStateModel => state.view);

@Injectable()
export class MonolithStateService {
	constructor(private _state: NgRedux<IAppState>) { }

	public storeAttendees = (attendees: any[]): void => this.dispatch(APP_STATE_ACTIONS.STORE_ATTENDEES, attendees);

	public watchAttendees = (): Observable<any[]> => this.select(getAttendees);

	public storeCommentators = (commentators: any[]): void => this.dispatch(APP_STATE_ACTIONS.STORE_COMMENTATORS, commentators);

	public watchCommentators = (): Observable<any[]> => this.select(getCommentators);

	public storeCurrentGame = (game: IGameModel): void => this.dispatch(APP_STATE_ACTIONS.STORE_CURRENT_GAME, game);

	public watchCurrentGame = (): Observable<IGameModel> => this.select(getCurrentGame);

	public storeDonations = (donations: IDonationModel[]): void => this.dispatch(APP_STATE_ACTIONS.STORE_DONATIONS, donations);

	public watchDonations = (): Observable<IDonationModel[]> => this.select(getDonations);

	public storeDonationTotals = (donationTotals: IDonationTotalsModel): void => this.dispatch(APP_STATE_ACTIONS.STORE_DONATION_TOTALS, donationTotals);

	public watchDonationTotals = (): Observable<IDonationTotalsModel> => this.select(getDonationTotals);

	public storeMilestones = (milestones: IMilestoneModel[]): void => this.dispatch(APP_STATE_ACTIONS.STORE_MILESTONES, milestones);

	public watchMilestones = (): Observable<IMilestoneModel[]> => this.select(getMilestones);

	public storeBids = (bids: IBidGroupModel[]): void => this.dispatch(APP_STATE_ACTIONS.STORE_BIDS, bids);

	public watchBids = (): Observable<IBidGroupModel[]> => this.select(getBids);

	public storeChallenges = (challenges: IChallengeModel[]): void => this.dispatch(APP_STATE_ACTIONS.STORE_CHALLENGES, challenges);

	public watchChallenges = (): Observable<IChallengeModel[]> => this.select(getChallenges);

	public storeMusic = (music: IMusicInfoModel): void => this.dispatch(APP_STATE_ACTIONS.STORE_MUSIC, music);

	public watchMusic = (): Observable<IMusicInfoModel> => this.select(getMusic);

	public storePlayers = (players: IPlayerInfoModel[]): void => this.dispatch(APP_STATE_ACTIONS.STORE_PLAYERS, players);

	public watchPlayers = (): Observable<IPlayerInfoModel[]> => this.select(getPlayers);

	public storeSchedule = (schedule: IGameModel[]): void => this.dispatch(APP_STATE_ACTIONS.STORE_SCHEDULE, schedule);

	public watchSchedule = (): Observable<IGameModel[]> => this.select(getSchedule);

	public storeAuth = (auth: IAuthState): void => this.dispatch(APP_STATE_ACTIONS.STORE_AUTH, auth);

	public watchAuth = (): Observable<IAuthState> => this.select(getAuth);

	public storeView = (view: IViewStateModel): void => this.dispatch(APP_STATE_ACTIONS.STORE_VIEW, view);

	public watchView = (): Observable<IViewStateModel> => this.select(getView);

	public storeElementMeta = <T extends IGenericElementDataModel>(view: string, id: string, meta: T): void => this.dispatch(APP_STATE_ACTIONS.STORE_ELEMENT_META, { view: view, id: id, meta: meta });

	public watchElementMeta = <T extends IGenericElementDataModel>(view: string, id: string): Observable<T> => this.select(getElementMeta<T>(view, id));

	public importSavedData = (data: object): void => this.dispatch(APP_STATE_ACTIONS.IMPORT_SAVED_DATA, data);

	// sanitize state for saveable data
	public getSaveableState = (): string => {
		const state = { ...this._state.getState() } as object;

		delete state['auth'];
		delete state['view'];

		return btoa(JSON.stringify(state));
	}

	// #region Internal

	private dispatch(action: APP_STATE_ACTIONS, payload: any): void {
		this._state.dispatch({ type: action, payload: payload });
	}

	private select<T>(selector: Selector<IAppState, T>): Observable<T> {
		return this._state
			.select(selector)
			.pipe(
				map((x) => deepCopy(x))
			);
	}

	// #endregion
}
