import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { ASSET_TYPE_ENUM } from 'core/models/asset.models';

import { IChannelDisplayModel, IPlayerInfoModel } from 'element/models/player-info.models';
import { ProfileManager } from 'profile/services/profile.manager';

@Component({
	selector: 'stream-player-info-component',
	templateUrl: './player-info.component.html'
})
export class PlayerInfoComponent implements OnInit, OnDestroy {
	@Input() public playerModel: IPlayerInfoModel;
	public currentChannelInfo: IChannelDisplayModel;

	public imageData: SafeResourceUrl;
	public hasImage: boolean = false;

	private _channelIndex: number;
	private _channelIntervalInSeconds: number = 30;
	private _timer: number;
	private _displayAlias: boolean;

	constructor(
		private _profileManager: ProfileManager,
		private _sanitizer: DomSanitizer
	) { }

	public ngOnInit(): void {
		if (this.playerModel && this.playerModel.channels) {
			this._channelIndex = 0;
			this._displayAlias = true;
			this.currentChannelInfo = this.getPlayer();
		}

		const channelUpdateInterval: number = this._channelIntervalInSeconds * 1000;

		// HACK: better way to check if channel data populated
		if (this.playerModel.channels &&
			(this.playerModel.channels.length > 0) &&
			(this.playerModel.channels[0].handle)) {
			this._timer = setInterval(() => this.updateCurrentChannel(), channelUpdateInterval) as any as number;
		}
	}

	public ngOnDestroy(): void {
		if (this._timer) {
			clearInterval(this._timer);
		}
	}

	// #region Internal

	private updateImageData(): void {
		if (this.currentChannelInfo && this.currentChannelInfo.icon) {
			this.imageData = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + this.currentChannelInfo.icon);
		}
		else {
			this.imageData = null;
		}

		this.hasImage = !!this.imageData;
	}

	private updateCurrentChannel(): void {
		if (this.playerModel.channels.length > 0) {
			if (this._displayAlias) {
				this.currentChannelInfo = this.getCurrentChannel();
				this._displayAlias = false;
			}
			else {
				this._channelIndex++;
				if (this._channelIndex > this.playerModel.channels.length - 1) {
					this._channelIndex = 0;
					this._displayAlias = true;
					this.currentChannelInfo = this.getPlayer();
				}
				else {
					this.currentChannelInfo = this.getCurrentChannel();
				}
			}
		}

		this.updateImageData();
	}

	private getPlayer(): IChannelDisplayModel {
		return {
			icon: this.playerModel.image,
			name: this.playerModel.alias
		};
	}

	private getCurrentChannel(): IChannelDisplayModel {
		return {
			icon: this._profileManager.getAsset(this.playerModel.channels[this._channelIndex].type, ASSET_TYPE_ENUM.Social),
			name: this.playerModel.channels[this._channelIndex].handle
		};
	}

	// #endregion
}
