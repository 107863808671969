import { APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { ElementModule } from './element/element.module';
import { ProfileModule } from './profile/profile.module';

@NgModule({
	bootstrap: [AppComponent],
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		RouterModule,
		RouterModule.forRoot([]),
		CoreModule,

		ElementModule,
		ProfileModule
	],
	providers: [
		{ provide: APP_BASE_HREF, useValue: '/' }
	]
})
export class AppModule { }
