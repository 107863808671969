import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiServiceBase } from 'core/services/api/api-service.base';

import { IProfileLoadRequestModel, IProfileLoadResponseModel } from 'profile/models/profile-api.models';

@Injectable()
export class ProfileApiService extends ApiServiceBase {
	protected basePath: string = 'profiles';

	constructor(http: HttpClient) {
		super(http);
	}

	public load(channel: string, profile: string): Observable<IProfileLoadResponseModel> {
		return this.get<IProfileLoadResponseModel>('load', {
			channel: channel,
			profile: profile
		} as IProfileLoadRequestModel);
	}
}
