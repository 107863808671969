import { ComponentFactoryResolver, ComponentRef, Inject, Injectable, ViewContainerRef } from '@angular/core';

import { IHash } from 'core/models/hash.models';

import { MonolithStateService } from 'core/services/state/monolith-state.service';
import { IStorageService, storageServiceToken } from 'core/services/storage/storage.service';

import { ViewComponent } from 'profile/components/view/view.component';
import { IViewModel, IViewStateModel } from 'profile/models/view.models';

enum LOCAL_STORAGE_KEYS {
	ViewInfo = 'view_info'
}

@Injectable()
export class ViewManager {
	private _activeView: string = null;
	private _views: IHash<ComponentRef<ViewComponent>> = {};
	private _transitioner?: number = null;

	constructor(
		private _state: MonolithStateService,
		private _componentFactoryResolver: ComponentFactoryResolver,
		@Inject(storageServiceToken) private _storage: IStorageService
	) { }

	public getStoredViewState(): IViewStateModel {
		return this._storage.getValue(LOCAL_STORAGE_KEYS.ViewInfo);
	}

	public loadView(view: IViewModel, container: ViewContainerRef): ViewComponent {
		// dynamically load view component, set incoming view model
		if (!this._views[view.id]) {
			const componentFactory = this._componentFactoryResolver.resolveComponentFactory(ViewComponent);
			const viewComponent = container.createComponent(componentFactory);

			viewComponent.instance.model = view;
			viewComponent.location.nativeElement.classList.add(`view-${view.id}`);

			this._views[view.id] = viewComponent;
		}

		return this._views[view.id].instance;
	}

	public unloadView(id: string): void {
		if (!this._views[id]) { return; }

		// pre-notify listeners of destroy, so they can transition before actual destroy
		this._views[id].instance.unloaded.emit(id);

		// unload component - should clean up its own styles, dynamic elements, SVGs, etc
		this._views[id].destroy();

		delete this._views[id];
	}

	public transitionToView(id: string, delay: number = 0, variants: string[] = []): void {
		if (this._transitioner) {
			clearTimeout(this._transitioner);
		}

		// notify any watchers of the transition
		this._state.storeView({
			active: this._activeView,
			transitionTo: id,
			variants: variants
		} as IViewStateModel);

		// if delay is null, set transition, but do not complete it until told
		if (delay !== null) {
			// double type assertion - TS wants to use NodeJS.Timer, which does not exist in a browser environment
			this._transitioner = setTimeout(() => {
				this.completeTransition(id, variants);
			}, delay) as any as number;
		}
	}

	public applyVariants(variants: string[]): void {
		this.persistViewState(this._activeView, variants);
	}

	private completeTransition(id: string, variants: string[]): void {
		this._activeView = id;

		this.persistViewState(id, variants);
	}

	private persistViewState(id: string, variants: string[]): void {
		const newViewState = {
			active: id,
			transitionTo: null,
			variants: variants
		} as IViewStateModel;

		// store for use on reconnect
		this._storage.setValue(LOCAL_STORAGE_KEYS.ViewInfo, newViewState);

		// notify other views of change
		this._state.storeView(newViewState);
	}
}
