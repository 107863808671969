import { Component } from '@angular/core';
import { Subscription } from 'rxjs';

import { MonolithStateService } from 'core/services/state/monolith-state.service';

import { ElementBase } from 'element/components/element-base/element.base';
import { IGenericElementDataModel } from 'element/models/element.models';
import { IMusicInfoModel } from 'element/models/music-info.models';

@Component({
	selector: 'stream-music-info-component',
	templateUrl: './music-info.component.html'
})
export class MusicInfoComponent extends ElementBase<IGenericElementDataModel> {
	public musicInfo: IMusicInfoModel = {} as IMusicInfoModel;
	public isMusicPlaying: boolean = false;

	constructor(state: MonolithStateService) {
		super(state);
	}

	protected initSubscriptions(): Subscription[] {
		return [
			...super.initSubscriptions(),
			this.state.watchMusic()
				.subscribe((result) => {
					this.musicInfo = result;
					this.isMusicPlaying = result && !!result.trackTitle; // !! properly converts the string to boolean
				})
		];
	}
}
