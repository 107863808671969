import { Component } from '@angular/core';

import { MonolithStateService } from 'core/services/state/monolith-state.service';

import { ITextModel } from 'element/models/text.models';
import { ElementBase } from '../element-base/element.base';

@Component({
	selector: 'stream-text',
	templateUrl: './text.component.html'
})
export class TextComponent extends ElementBase<ITextModel> {
	constructor(state: MonolithStateService) {
		super(state);
	}
}
