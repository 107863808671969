
export interface IStateAction {
	type: string;
	payload: any;
}

export enum APP_STATE_ACTIONS {
	STORE_ASSETS = 'app_store_assets',
	STORE_AUTH = 'app_store_auth',

	STORE_CURRENT_GAME = 'app_store_current_game',
	STORE_SCHEDULE = 'app_store_schedule',

	STORE_DONATIONS = 'app_store_donations',
	STORE_DONATION_TOTALS = 'app_store_donation_totals',
	STORE_MILESTONES = 'app_store_milestones',
	STORE_BIDS = 'app_store_bids',
	STORE_CHALLENGES = 'app_store_challenges',

	STORE_MUSIC = 'app_store_music',

	STORE_PLAYERS = 'app_store_players',
	STORE_COMMENTATORS = 'app_store_commentators',
	STORE_ATTENDEES = 'app_store_attendees',

	STORE_VIEW = 'app_store_view',

	STORE_ELEMENT_META = 'app_store_element_meta',

	IMPORT_SAVED_DATA = 'app_import_saved_data'
}
