export enum ELEMENT_TYPE_ENUM {
	Attendees = 'attendees',
	Bids = 'bids',
	Challenges = 'challenges',
	Commentators = 'commentators',
	CurrentGame = 'current_game',
	Donations = 'donations',
	DonationTotals = 'donation_totals',
	Images = 'images',
	Markup = 'markup',
	Milestones = 'milestones',
	Music = 'music',
	Players = 'players',
	Schedule = 'schedule',
	Text = 'text',
	Viewport = 'viewport'
}
