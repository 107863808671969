export enum AUTH_STATUS_ENUM {
	Connecting = 'connecting',	// on startup, initiating connection
	Awaiting = 'awaiting',			// verified and websocket connected, awaiting admin auth
	Connected = 'connected',				// authorized by admin
	Rejected = 'rejected',			// connection failed or authorization rejected
	Disconnected = 'disconnected',	// unexpected loss of connection
	Reconnecting = 'reconnecting'	// connection lost, attempting reconnect
}

export interface IAuthState {
	status: AUTH_STATUS_ENUM;
	lastAction: Date;
}

export interface IAuthRequest {
	channel: string;
	key: string;
}

export interface IAuthResponse {
	id: string;
	channel: string;
	profile: string;
}

export interface IReconnectRequest {
	channel: string;
	profile: string;
}
