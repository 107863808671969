import { Component } from '@angular/core';
import { Observable } from 'rxjs';

import { MonolithStateService } from 'core/services/state/monolith-state.service';

import { ElementBase } from 'element/components/element-base/element.base';
import { IGenericElementDataModel } from 'element/models/element.models';
import { IGameModel } from 'element/models/game.models';

@Component({
	selector: 'stream-upcoming-games-container-component',
	templateUrl: './upcoming-games-container.component.html',
})

// TODO: rename this (and children) to schedule
export class UpcomingGamesContainerComponent extends ElementBase<IGenericElementDataModel> {
	public upcomingGamesStream: Observable<IGameModel[]>;

	constructor(state: MonolithStateService) {
		super(state);
	}

	protected initObservables(): Observable<IGameModel[]>[] {
		return [
			...super.initObservables(),
			this.upcomingGamesStream = this.state.watchSchedule()
		];
	}
}
