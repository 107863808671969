import { DevToolsExtension, NgRedux, NgReduxModule } from '@angular-redux/store';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { environment } from 'environment';
import { StoreEnhancer } from 'redux';

import { DEFAULT_APP_STATE, IAppState } from './state/app-state.model';
import { appReducer } from './state/app-state.reducer';

import { AuthComponent } from './components/auth/auth.component';
import { AuthManager } from './services/auth/auth.manager';
import { AuthService } from './services/auth/auth.service';

import { MonolithStateService } from './services/state/monolith-state.service';

import { LocalStorageModule } from 'angular-2-local-storage';
import { LocalStorageService } from './services/storage/local-storage.service';
import { storageServiceToken } from './services/storage/storage.service';

import { NativeWebsocketService } from './services/websocket/native-websocket.service';
import { websocketServiceToken } from './services/websocket/websocket.service';

import { AttachJwtInterceptor } from './services/http/attach-jwt.interceptor';
import { EnforceJsonInterceptor } from './services/http/enforce-json.interceptor';
import { DocumentManager } from './utility/document.manager';

@NgModule({
	declarations: [
		AuthComponent
	],
	imports: [
		CommonModule,
		NgReduxModule,
		HttpClientModule,

		LocalStorageModule.forRoot({
			prefix: 'stream',
			storageType: 'localStorage'
		})
	],
	providers: [
		AuthManager,
		AuthService,
		DocumentManager,
		MonolithStateService,
		{ provide: storageServiceToken, useClass: LocalStorageService },
		{ provide: websocketServiceToken, useClass: NativeWebsocketService },

		// interceptors
		{ provide: HTTP_INTERCEPTORS, useClass: EnforceJsonInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: AttachJwtInterceptor, multi: true }
	],
	exports: [
		AuthComponent
	]
})
export class CoreModule {
	constructor(
		ngRedux: NgRedux<IAppState>,
		devTools: DevToolsExtension
	) {
		const enhancers: StoreEnhancer<IAppState, {}>[] = [];
		const initialState = { ...DEFAULT_APP_STATE };

		// load dev tools for non-production environments, when available and enabled
		if (!environment.production && devTools.isEnabled()) {
			enhancers.push(devTools.enhancer());
		}

		ngRedux.configureStore(appReducer, initialState, undefined, enhancers);

		// TODO: persist state changes - local storage?
		// ngRedux.subscribe((currentState) => {
		// });
	}
}
