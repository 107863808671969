import { Component } from '@angular/core';

import { MonolithStateService } from 'core/services/state/monolith-state.service';

import { ElementBase } from 'element/components/element-base/element.base';
import { IGenericElementDataModel } from 'element/models/element.models';

@Component({
	selector: 'stream-viewport-component',
	templateUrl: './viewport.component.html'
})
export class ViewportComponent extends ElementBase<IGenericElementDataModel> {
	constructor(state: MonolithStateService) {
		super(state);
	}
}
