export function formatCurrency(value: number, digits: number = 2, showSymbol: boolean = true): string {
	const options: Intl.NumberFormatOptions = {
		currency: 'USD',
		style: showSymbol ? 'currency' : 'decimal',
		minimumFractionDigits: digits,
		maximumFractionDigits: digits
	};

	return value.toLocaleString('en-US', options);
}
