// TODO: can't union enums?
// TODO: another good shared lib candidate
export enum WEBSOCKET_MESSAGE_TYPE {
	// command types
	FrontendConnect = 'frontend_connect',
	FrontendDisconnect = 'frontend_disconnect',
	FrontendPing = 'frontend_ping',
	FrontendPong = 'frontend_pong',
	FrontendTransition = 'frontend_transition',
	FrontendVariants = 'frontend_variants',

	// data types
	Attendees = 'attendees',
	Bids = 'bids',
	Challenges = 'challenges',
	Commentators = 'commentators',
	CurrentGame = 'current_game',
	Donations = 'donations',
	DonationTotals = 'donation_totals',
	Milestones = 'milestones',
	Music = 'music',
	Players = 'players',
	Schedule = 'schedule',

	// element control type
	ElementUpdate = 'element_update',

	// system types
	LostConnection = 'system_lost_connection'
}

export interface IWebsocketMessageModel {
	type: WEBSOCKET_MESSAGE_TYPE;
	token: string;
	data: any;
}

export type IWebsocketHandler<T> = (data?: T) => void;

export interface IWebsocketHandlerEntry {
	token: string;
	handler: IWebsocketHandler<any>;
}

export interface IWebsocketConnectModel {
	id: string;
	channel: string;
	profile: string;
}

export interface IWebsocketAuthorizeModel {
	token: string;
}
