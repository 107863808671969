import { ComponentFactory, ComponentFactoryResolver, Injectable, ViewContainerRef } from '@angular/core';

import { IHash } from 'core/models/hash.models';

import { IGenericElementDataModel } from 'element/models/element.models';
import { ELEMENT_TYPE_ENUM } from 'element/utility/element.enum';
import { IViewElementModel } from 'profile/models/view.models';

import { BidsComponent } from 'element/components/bids/bids.component';
import { ChallengesComponent } from 'element/components/challenges/challenges.component';
import { CurrentGameComponent } from 'element/components/current-game/current-game.component';
import { DonationTotalsComponent } from 'element/components/donation-totals/donation-totals.component';
import { DonationsContainerComponent } from 'element/components/donations-container/donations-container.component';
import { ElementBase } from 'element/components/element-base/element.base';
import { MarkupComponent } from 'element/components/markup/markup.component';
import { MilestonesComponent } from 'element/components/milestones/milestones.component';
import { MusicInfoComponent } from 'element/components/music-info/music-info.component';
import { PlayerInfoContainerComponent } from 'element/components/player-info-container/player-info-container.component';
import { TextComponent } from 'element/components/text/text.component';
import { UpcomingGamesContainerComponent } from 'element/components/upcoming-games-container/upcoming-games-container.component';
import { ViewportComponent } from 'element/components/viewport/viewport.component';

@Injectable()
export class ElementManager {
	private _factories: IHash<ComponentFactory<any>> = {};

	constructor(
		componentFactoryResolver: ComponentFactoryResolver
	) {
		// cache component factories _once_ on load
		this._factories[ELEMENT_TYPE_ENUM.Bids] = componentFactoryResolver.resolveComponentFactory(BidsComponent);
		this._factories[ELEMENT_TYPE_ENUM.Challenges] = componentFactoryResolver.resolveComponentFactory(ChallengesComponent);
		this._factories[ELEMENT_TYPE_ENUM.CurrentGame] = componentFactoryResolver.resolveComponentFactory(CurrentGameComponent);
		this._factories[ELEMENT_TYPE_ENUM.Donations] = componentFactoryResolver.resolveComponentFactory(DonationsContainerComponent);
		this._factories[ELEMENT_TYPE_ENUM.DonationTotals] = componentFactoryResolver.resolveComponentFactory(DonationTotalsComponent);
		this._factories[ELEMENT_TYPE_ENUM.Markup] = componentFactoryResolver.resolveComponentFactory(MarkupComponent);
		this._factories[ELEMENT_TYPE_ENUM.Milestones] = componentFactoryResolver.resolveComponentFactory(MilestonesComponent);
		this._factories[ELEMENT_TYPE_ENUM.Music] = componentFactoryResolver.resolveComponentFactory(MusicInfoComponent);
		this._factories[ELEMENT_TYPE_ENUM.Players] = componentFactoryResolver.resolveComponentFactory(PlayerInfoContainerComponent);
		this._factories[ELEMENT_TYPE_ENUM.Schedule] = componentFactoryResolver.resolveComponentFactory(UpcomingGamesContainerComponent);
		this._factories[ELEMENT_TYPE_ENUM.Text] = componentFactoryResolver.resolveComponentFactory(TextComponent);
		this._factories[ELEMENT_TYPE_ENUM.Viewport] = componentFactoryResolver.resolveComponentFactory(ViewportComponent);
	}

	public loadElement<T extends IGenericElementDataModel>(view: string, element: IViewElementModel<T>, container: ViewContainerRef): void {
		const componentFactory = this._factories[element.type];
		if (!componentFactory) {
			return;
		}

		const component = container.createComponent(componentFactory);

		// TODO: move to element base initialize?
		// can't set IDs - could create non-unique IDs across views
		component.location.nativeElement.classList.add(element.id);

		// set a generic class for viewports - needed for proper layering
		if (element.type === ELEMENT_TYPE_ENUM.Viewport) {
			component.location.nativeElement.classList.add('viewport');
		}
		else {
			// set a generic class for non-viewport elements
			component.location.nativeElement.classList.add('element');
		}

		// set view and initial element meta
		(component.instance as ElementBase<T>).initialize(view, element);
	}
}
