import { Component } from '@angular/core';
import { Subscription } from 'rxjs';

import { MonolithStateService } from 'core/services/state/monolith-state.service';
import { formatCurrency } from 'core/utility/format.utility';

import { ElementBase } from 'element/components/element-base/element.base';
import { IBidGroupModel, IBidOptionModel } from 'element/models/bid.models';
import { IGenericElementDataModel } from 'element/models/element.models';

const sortByAmountDesc = (options: IBidOptionModel[]) => {
	return options.sort((a, b) => {
		if (!a || !b) { return 0; }

		if (a.amount > b.amount) { return -1; }

		if (a.amount < b.amount) { return 1; }

		return 0;
	});
};

@Component({
	selector: 'stream-bids-component',
	templateUrl: './bids.component.html'
})
export class BidsComponent extends ElementBase<IGenericElementDataModel> {
	public bidGroups: IBidGroupModel[] = [];

	constructor(state: MonolithStateService) {
		super(state);
	}

	protected initSubscriptions(): Subscription[] {
		return [
			...super.initSubscriptions(),
			this.state.watchBids()
				.subscribe((result) => {
					result.forEach((x) => {
						x.options = sortByAmountDesc(x.options);
					});

					this.bidGroups = result;
				})
		];
	}

	public formatOptionAmount(option: IBidOptionModel): string {
		return formatCurrency(option.amount);
	}
}
