<div class="upcoming-game-wrapper">
	<div class="upcoming-game-info">
		<div class="upcoming-game-name">
			{{ upcomingGameModel.gameName }}
		</div>
		<div class="upcoming-game-runners">
			{{ upcomingGameModel.runners }}
		</div>
		<div class="upcoming-game-platform">
			{{upcomingGameModel.gamePlatform }}
		</div>
		<div class="upcoming-game-release-year">
			{{ upcomingGameModel.gameReleaseYear }}
		</div>
		<div class="upcoming-game-duration">
			{{ upcomingGameModel.expectedDuration }}
		</div>
		<div class="upcoming-game-run-category">
			{{ upcomingGameModel.runCategory }}
		</div>
	</div>
</div>
