import { Directive, HostBinding } from '@angular/core';
import { Subscription } from 'rxjs';

import { ObservableComponentBase } from 'core/components/base/observable-component.base';
import { MonolithStateService } from 'core/services/state/monolith-state.service';
import { DEFAULT_ELEMENT_META, IGenericElementDataModel } from 'element/models/element.models';
import { IViewElementModel } from 'profile/models/view.models';

@Directive()	// using plain @Injectable decorator breaks @HostBinding
// tslint:disable-next-line: directive-class-suffix
export abstract class ElementBase<T extends IGenericElementDataModel> extends ObservableComponentBase {
	protected viewId: string;
	protected elementId: string;
	public meta: T = DEFAULT_ELEMENT_META as T;

	// show by default
	@HostBinding('class.element-hidden')
	private _isHidden: boolean = false;

	constructor(protected state: MonolithStateService) {
		super();
	}

	protected initSubscriptions(): Subscription[] {
		return [
			this.state.watchElementMeta<T>(this.viewId, this.elementId)
				.subscribe((result) => {
					if (result) {
						this.meta = result;
						this._isHidden = (this.meta.isVisible === false);
					}
				})
		];
	}

	public initialize(view: string, element: IViewElementModel<T>) {
		this.viewId = view;
		this.elementId = element.id;
		this.meta = element.data || (DEFAULT_ELEMENT_META as T);
	}
}
