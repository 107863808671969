import { InjectionToken } from '@angular/core';

import { IWebsocketConnectModel, IWebsocketHandler, IWebsocketMessageModel, WEBSOCKET_MESSAGE_TYPE } from 'core/models/websocket.models';

export interface IWebsocketService {
	connect: (info: IWebsocketConnectModel) => void;
	disconnect: (clearHandlers: boolean) => void;
	handle: (type: WEBSOCKET_MESSAGE_TYPE, handler: IWebsocketHandler<any>, token?: string) => string;
	unhandle: (type: WEBSOCKET_MESSAGE_TYPE, token: string) => void;
	send: (message: IWebsocketMessageModel) => void;
}

export const websocketServiceToken = new InjectionToken<IWebsocketService>('WebsocketService');
