import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiServiceBase } from 'core/services/api/api-service.base';

import { ISendStateRequestModel } from 'profile/models/frontend-api.models';

@Injectable()
export class FrontendApiService extends ApiServiceBase {
	protected basePath: string = 'frontends';

	constructor(http: HttpClient) {
		super(http);
	}

	public saveState(channel: string, profile: string, state: string): Observable<void> {
		return this.put<void>('state', {
			channel: channel,
			profile: profile,
			state: state
		} as ISendStateRequestModel);
	}
}
