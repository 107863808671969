import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { BidsComponent } from './components/bids/bids.component';
import { ChallengesComponent } from './components/challenges/challenges.component';
import { CurrentGameComponent } from './components/current-game/current-game.component';
import { DonationTotalsComponent } from './components/donation-totals/donation-totals.component';
import { DonationComponent } from './components/donation/donation.component';
import { DonationsContainerComponent } from './components/donations-container/donations-container.component';
import { DonationsProgressComponent } from './components/donations-progress/donations-progress.component';
import { MarkupComponent } from './components/markup/markup.component';
import { MilestonesComponent } from './components/milestones/milestones.component';
import { MusicInfoComponent } from './components/music-info/music-info.component';
import { PlayerInfoContainerComponent } from './components/player-info-container/player-info-container.component';
import { PlayerInfoComponent } from './components/player-info/player-info.component';
import { TextComponent } from './components/text/text.component';
import { UpcomingGameComponent } from './components/upcoming-game/upcoming-game.component';
import { UpcomingGamesContainerComponent } from './components/upcoming-games-container/upcoming-games-container.component';
import { ViewportComponent } from './components/viewport/viewport.component';

import { ElementManager } from './services/element.manager';

@NgModule({
	declarations: [
		BidsComponent,
		ChallengesComponent,
		CurrentGameComponent,
		DonationsContainerComponent,
		DonationComponent,
		DonationsProgressComponent,
		DonationTotalsComponent,
		MarkupComponent,
		MilestonesComponent,
		MusicInfoComponent,
		PlayerInfoComponent,
		PlayerInfoContainerComponent,
		TextComponent,
		UpcomingGameComponent,
		UpcomingGamesContainerComponent,
		ViewportComponent
	],
	exports: [
		BidsComponent,
		ChallengesComponent,
		CurrentGameComponent,
		DonationTotalsComponent,
		MarkupComponent,
		MilestonesComponent,
		MusicInfoComponent,
		PlayerInfoComponent,
		PlayerInfoContainerComponent,
		TextComponent,
		UpcomingGameComponent,
		UpcomingGamesContainerComponent,
		ViewportComponent
	],
	imports: [
		CommonModule,
		FormsModule
	],
	providers: [
		ElementManager
	],
	entryComponents: [
		BidsComponent,
		ChallengesComponent,
		CurrentGameComponent,
		DonationsContainerComponent,
		DonationTotalsComponent,
		MarkupComponent,
		MilestonesComponent,
		MusicInfoComponent,
		PlayerInfoContainerComponent,
		TextComponent,
		UpcomingGamesContainerComponent,
		ViewportComponent
	]
})
export class ElementModule { }
