import { Injectable } from '@angular/core';
import { IStorageService } from './storage.service';

import { LocalStorageService as ng2Ls } from 'angular-2-local-storage';

@Injectable()
export class LocalStorageService implements IStorageService {
	constructor(private _localStorage: ng2Ls) { }

	public getValue(key: string): any {
		return this._localStorage.get(key);
	}

	public setValue(key: string, value: any) {
		this._localStorage.set(key, value);
	}

	public clearValue(key: string): void {
		this._localStorage.remove(key);
	}
}
